import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['tab']
  static values = { focusedTabIndex: Number }

  markActive(e) {
    this.tabTargets.forEach(tab => {
      if (tab === e.currentTarget.parentElement) {
        tab.dataset.active = true
        tab.children[0].setAttribute('aria-selected', true)  
      } else {
        tab.dataset.active = false
        tab.children[0].setAttribute('aria-selected', false)
      }
    })
  }

  nextTab(e) {
    // Make current tab not tabbable
    e.currentTarget.setAttribute('tabindex', -1)
    
    // Go to the first tab if overshooting
    if (this.focusedTabIndexValue >= this.tabTargets.length - 1) {
      this.focusedTabIndexValue = 0
    } else {
      this.focusedTabIndexValue++;
    }

    this.focusTab()
  }

  prevTab(e) {
    // Make current tab not tabbable
    e.currentTarget.setAttribute('tabindex', -1)

    // Go to the last tab if overshooting
    if (this.focusedTabIndexValue == 0) {
      this.focusedTabIndexValue = this.tabTargets.length - 1
    } else {
      this.focusedTabIndexValue--;
    }

    this.focusTab()
  }

  focusTab() {
    // Make new tab tabbable and focus it
    this.tabTargets[this.focusedTabIndexValue].children[0].setAttribute('tabindex', 0)
    this.tabTargets[this.focusedTabIndexValue].children[0].focus()
  }
}
